import { Cell, Pie, PieChart } from "recharts"
import { employeeMetrics } from "@/app/routes/dashboard"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { ChartContainer, ChartLegend, ChartLegendContent, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart"
import { cn } from "@/lib/utils"

const employeeChartConfig = {
  headcount: {
    label: "Headcount",
  },
  Sales: {
    label: "Sales",
    color: "hsl(var(--chart-1))",
  },
  Engineering: {
    label: "Engineering",
    color: "hsl(var(--chart-2))",
  },
  Marketing: {
    label: "Marketing",
    color: "hsl(var(--chart-3))",
  },
  Support: {
    label: "Support",
    color: "hsl(var(--chart-4))",
  },
  Finance: {
    label: "Finance",
    color: "hsl(var(--chart-5))",
  },
}

type EmployeeDistributionChartProps = {
  className?: string,
}

const EmployeeDistributionChart = ({ className }: EmployeeDistributionChartProps) => {
  return (
    <Card className={cn("flex flex-col", className)}>
      <CardHeader>
        <CardTitle>Employee Distribution</CardTitle>
        <CardDescription>Current Distribution</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer
          config={employeeChartConfig}
          className="min-h-[60px] h-60 w-full"
        >
          <PieChart>
            <ChartTooltip
              content={<ChartTooltipContent indicator="dashed" />}
            />
            <Pie
              data={employeeMetrics}
              dataKey="headcount"
              nameKey="department"
              label
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill="#8884d8"
            >
              {employeeMetrics.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={`var(--color-${entry.department})`} />
              ))}
            </Pie>
            <ChartLegend
              content={<ChartLegendContent nameKey="department" />}
              // className="flex-wrap gap-2 [&>*]:basis-1/3 [&>*]:justify-center"
            />
          </PieChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="gap-2 flex-col items-start text-sm">
        <div className="gap-2 font-medium leading-none">
          Total employees: {employeeMetrics.reduce((sum, { headcount }) => sum + headcount, 0)}
        </div>
        <div className="leading-none text-muted-foreground">
          Showing current employee distribution across departments
        </div>
      </CardFooter>
    </Card>
  )
}

export { EmployeeDistributionChart }