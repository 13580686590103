import { api } from "./api";

export type PlatformData = {
  companyName:string,
  companyDomain:string
}

export const profileApi = {
  platformSet(data: PlatformData) {
    return api.post<PlatformData>(
      '/user/profile/platform',
      data,
    )
  },
  platformGet(){
    return api.get<PlatformData | null>(
      '/user/profile/platform'
    )
  },
  platformUpdate(data: Partial<PlatformData>){
    return api.patch<PlatformData>(
      '/user/profile/platform',
      data
    )
  }
}