import { useTheme } from "@/components/theme-provider"
import { APP_BASE_URL } from "@/lib/utils"

export const logoHooks = {
  useFullLogo: () => {
    const { theme } = useTheme()
    const fullLogo = theme === 'dark' ? 'full_logo_white.png' : 'full_logo.png'
    return `${APP_BASE_URL}/images/${fullLogo}`
  },
  useLogoIcon: () => {
    return `${APP_BASE_URL}/images/white_logo-192x192.png`
  },
  useFavIcon: () => {
    return `${APP_BASE_URL}/images/favicon.ico`
  },
  useAllLogos: () => {
    const fullLogoUrl = logoHooks.useFullLogo()
    const logoIconUrl = logoHooks.useLogoIcon()
    const favIconUrl = logoHooks.useFavIcon()

    return {
      fullLogoUrl,
      logoIconUrl,
      favIconUrl,
    }
  },
}