import axios, { 
  AxiosError, 
  AxiosRequestConfig, 
  AxiosResponse,
} from "axios";
import qs from 'qs';

export const API_URL = `${window.location.origin}/api`;

function isUrlRelative(url: string) {
  return !url.startsWith('http') && !url.startsWith('https');
}

function request<TResponse>(
  url: string,
  config: AxiosRequestConfig = {},
): Promise<TResponse> {
  const resolvedUrl = !isUrlRelative(url) ? url : `${API_URL}${url}`;
  return axios({
    withCredentials: true,
    url: resolvedUrl,
    ...config,
    headers: {
      ...config.headers,
    },
  }).then((response) => response.data as TResponse);
}

export type HttpError = AxiosError<unknown, AxiosResponse<unknown>>;

export const api = {
  isError(error: unknown): error is HttpError {
    return axios.isAxiosError(error);
  },
  get: <TResponse>(url: string, query?: unknown) =>
    request<TResponse>(url, {
      params: query,
      paramsSerializer: (params) => {
        return qs.stringify(params, {
          arrayFormat: 'repeat',
        });
      },
    }),
  delete: <TResponse>(url: string, query?: Record<string, string>) =>
    request<TResponse>(url, {
      method: 'DELETE',
      params: query,
      paramsSerializer: (params) => {
        return qs.stringify(params, {
          arrayFormat: 'repeat',
        });
      },
    }),
  post: <TResponse, TBody = unknown, TParams = unknown>(
    url: string,
    body?: TBody,
    params?: TParams,
    headers?: Record<string, string>,
  ) =>
    request<TResponse>(url, {
      method: 'POST',
      data: body,
      headers: { 'Content-Type': 'application/json', ...headers },
      params: params,
    }),
  put: <TResponse, TBody = unknown, TParams = unknown>(
    url: string,
    body?: TBody,
    params?: TParams,
  ) =>
    request<TResponse>(url, {
      method: 'PUT',
      data: body,
      headers: { 'Content-Type': 'application/json' },
      params: params,
    }),
  patch: <TResponse, TBody = unknown, TParams = unknown>(
    url: string,
    body?: TBody,
    params?: TParams,
  ) =>
    request<TResponse>(url, {
      method: 'PATCH',
      data: body,
      headers: { 'Content-Type': 'application/json' },
      params: params,
    }),
};