import { api } from "./api";
import { Response } from "@/app.interface";

type Join = {
  id: string,
  email: string,
  created: string,
  updated: string
}

type Activate = Join;

type Resend = Join;

type Check = Join;

type Recover = Join;

export const authApi = {
  check(request: unknown) {
    return api.get<Response<Check>>(
      '/auth/local/check',
      request,
    );
  },
  signIn(request: unknown) {
    return api.post<unknown>(
      '/auth/local/login',
      request,
    );
  },
  signUp(request: unknown) {
    return api.put<Response<Join>>(
      '/auth/local/join',
      request,
    );
  },
  activate(request: unknown) {
    return api.post<Response<Activate>>(
      '/auth/local/activate',
      request,
    );
  },
  recover(request: unknown) {
    return api.post<Response<Recover>>(
      '/auth/local/recover',
      request,
    );
  },
  reset(request: unknown) {
    return api.post<Response<Recover>>(
      '/auth/local/reset',
      request,
    );
  },
  resend(request: unknown) {
    return api.post<Response<Resend>>(
      '/auth/local/resend',
      request,
    );
  },
  logout(request: unknown) {
    return api.get<Response<any>>(
      '/auth/local/logout',
      request,
    );
  },
}