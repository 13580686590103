import { useMemo, useState } from "react";
import "@nlux/themes/nova.css";
import './theme-variables.css';

import { Popover, PopoverAnchor, PopoverContent } from "@/components/ui/popover";
import { ChatTrigger } from "@/components/custom/chat-trigger";
import { AiChat, ChatItem, useAsBatchAdapter } from "@nlux/react";
import { batchCopilotAdapter } from "@/features/copilot/openai-adapter";
import { useTheme } from "@/components/theme-provider";
import { logoHooks } from "@/hooks/logos-hooks"
import { BOT_AVATAR_URL } from "@/lib/utils";

const chatHistory: ChatItem[] = [
  {
    role: "user",
    message: "Can you give me ",
  },
  {
    role: "assistant",
    message:
      "Sure! Here be a recipe for `spaghetti`:\n\n" +
      "## Spaghetti Recipe\n" +
      "\n" +
      "### Ingredients\n" +
      "- 200g spaghetti\n" +
      "- 2 tbsp olive oil\n" +
      "- 2 garlic cloves, minced\n" +
      "- 1 can (400g) diced tomatoes\n" +
      "- 1 tsp dried oregano\n" +
      "- 1 tsp dried basil\n" +
      "- Salt and pepper to taste\n" +
      "- Grated Parmesan cheese (optional)\n" +
      "- Fresh basil leaves (optional)\n" +
      "\n" +
      "### Instructions\n" +
      "1. **Cook Spaghetti**: Boil salted water, cook spaghetti until al dente, then drain.\n" +
      "2. **Make Sauce**: Heat olive oil, sauté garlic, add tomatoes, oregano, basil, salt, and pepper. Simmer for 10-15 mins.\n" +
      "3. **Combine**: Toss spaghetti with sauce. Top with Parmesan and basil if desired.\n" +
      "\n" +
      "Enjoy your meal!\n",
  },
]

const CopilotChat = () => {
  const { theme } = useTheme();
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  
  const adapter = useAsBatchAdapter(batchCopilotAdapter)
  const logo = logoHooks.useLogoIcon()
  const persona = useMemo(()=>({
    assistant: {
        name: "OpexAI Copilot",
        avatar: BOT_AVATAR_URL,
        tagline: "Helping to make sense of your Company's data!",
    },
    user: {
        name: "Alex",
        avatar: "https://github.com/shadcn.png",
    }
  }),[logo])

  const chatComponent = (
    <AiChat
      adapter={adapter}
      /* initialConversation={chatHistory} */
      displayOptions={{ 
        colorScheme: theme === "system" ? "auto" : theme,
        themeId: "chat",
        width: "100%",
      }}
      personaOptions={persona}
    />
  )

  return (
    <div className="fixed bottom-3 right-6">
      <Popover
        open={isChatOpen}
        onOpenChange={() => !isChatOpen && setIsChatOpen(false)}
      >
        <PopoverAnchor>
          <ChatTrigger
            pos="bottom"
            mode="popover"
            isChatOpen={isChatOpen}
            setIsChatOpen={setIsChatOpen}
          />
        </PopoverAnchor>
        <PopoverContent
          align="end"
          side="bottom"
          className="w-[33vw] h-[75vh] min-w-[300px] sm:min-w-[500px] p-0"
        >
          {chatComponent}
        </PopoverContent>
      </Popover>
    </div>
  );
}

export { CopilotChat }