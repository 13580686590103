import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="flex p-2 space-x-2">
      <p>This page is in development...</p>
      <Link 
        to="/"
        className="text-blue-600 dark:text-blue-500 hover:underline" 
      >
        Return to dashboard
      </Link>
    </div>
  );
}

export default NotFoundPage;
