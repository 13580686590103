import { Card, CardContent } from "@/components/ui/card"
import { Label } from "@/components/ui/label"
import { RadioGroupItem } from "@/components/ui/radio-group"

interface ThemeOptionProps {
  value: string
  label: string
}

const ThemeOption = ({ value, label }: ThemeOptionProps) => {
  const isDarkOption = value === "dark";

  return (
    <div>
      <RadioGroupItem value={value} id={value} className="peer sr-only" />
      <Label
        htmlFor={value}
        className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
      >
        <Card className={`w-full h-32 border-border ${isDarkOption ? "bg-gray-900" : "bg-white"}`}>
          <CardContent className="p-4">
            <div className={`h-4 w-3/4 rounded-lg mb-2 ${isDarkOption ? "bg-gray-600" : "bg-gray-200"}`} />
            <div className={`h-4 w-full rounded-lg mb-2 ${isDarkOption ? "bg-gray-700" : "bg-gray-300"}`} />
            <div className={`h-4 w-full rounded-lg ${isDarkOption ? "bg-gray-600" : "bg-gray-200"}`} />
          </CardContent>
        </Card>
        <span className="mt-2">{label}</span>
      </Label>
    </div>
  )
}

export { ThemeOption }
