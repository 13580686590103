import { AllowOnlyLoggedInUserOnlyGuard } from "./allow-logged-in-user-only-guard"
import Navbar from "@/components/custom/navbar"
import { Separator } from "@/components/ui/separator"
import { CopilotChat } from "@/features/copilot/copilot-chat"

type LoggedInPageLayoutProps = {
  children: React.ReactNode
}

export const LoggedInPageLayout = ({ 
  children
}: LoggedInPageLayoutProps) => {
  return (
    <div className="flex flex-col h-screen">
      <AllowOnlyLoggedInUserOnlyGuard>
        <Navbar />
        <Separator />
        <div className="flex-1 mx-auto max-w-screen-xl 2xl:max-w-[1700px] w-full py-4">
          {children}
        </div>
        <CopilotChat />
      </AllowOnlyLoggedInUserOnlyGuard>
    </div>
  )
}