import { useState } from "react";
import { DateRange } from "react-day-picker";
import { addDays } from "date-fns";
import { 
  PoundSterling, 
  Users, 
  Activity, 
  TrendingUp,
} from "lucide-react";

import { DatePickerWithRange } from "@/components/ui/date-picker-with-range";
import { 
  Card, 
  CardDescription, 
  CardTitle, 
  CardHeader, 
  CardContent, 
  CardFooter 
} from "@/components/ui/card";
import { RevenueVsTargetChart } from "@/features/dashboard/revenue-vs-target-chart";
import { EmployeeDistributionChart } from "@/features/dashboard/employee-distribution-chart";
import { DealsClosedChart } from "@/features/dashboard/deals-closed-chart";
import { SupportResolutionChart } from "@/features/dashboard/support-resolution-chart";
import { EmployeeTurnoverChart } from "@/features/dashboard/employee-turnover-chart";
import { CashFlowChart } from "@/features/dashboard/cash-flow-chart";

const dashboardCards = [
  {
    title: "Revenue",
    icon: PoundSterling,
    value: "£250,000",
    description: "+12% from last month",
  },
  {
    title: "Employee Satisfaction",
    icon: Users,
    value: "87%",
    description: "+3% from last quarter",
  },
  {
    title: "Customer Satisfaction",
    icon: Activity,
    value: "92%",
    description: "+5% from last quarter",
  },
  {
    title: "Deal Pipeline",
    icon: TrendingUp,
    value: "£1.2M",
    description: "+8% from last month",
  },
]

export const salesData = [
  { month: 'Jan', deals: 45, revenue: 180000, target: 200000 },
  { month: 'Feb', deals: 52, revenue: 210000, target: 200000 },
  { month: 'Mar', deals: 48, revenue: 190000, target: 200000 },
  { month: 'Apr', deals: 58, revenue: 240000, target: 200000 },
  { month: 'May', deals: 62, revenue: 250000, target: 200000 },
  { month: 'Jun', deals: 54, revenue: 220000, target: 195000 },
  { month: 'Jul', deals: 60, revenue: 235000, target: 170000 },
  { month: 'Aug', deals: 65, revenue: 260000, target: 140000 },
  { month: 'Sep', deals: 55, revenue: 225000, target: 180000 },
  { month: 'Oct', deals: 68, revenue: 275000, target: 200000 },
  { month: 'Nov', deals: 59, revenue: 240000, target: 200000 },
  { month: 'Dec', deals: 63, revenue: 255000, target: 200000 }
];

export const employeeMetrics = [
  { department: 'Sales', headcount: 25, turnover: 5 },
  { department: 'Engineering', headcount: 40, turnover: 3 },
  { department: 'Marketing', headcount: 15, turnover: 2 },
  { department: 'Support', headcount: 20, turnover: 4 },
  { department: 'Finance', headcount: 10, turnover: 1 }
];

export const supportMetrics = [
  { category: 'Technical', tickets: 145, resolved: 130 },
  { category: 'Billing', tickets: 85, resolved: 80 },
  { category: 'General', tickets: 95, resolved: 90 },
  { category: 'Feature', tickets: 65, resolved: 55 }
];

const DashboardPage = () => {
  const [date, setDate] = useState<DateRange | undefined>({
    from: new Date(2024, 0, 20),
    to: addDays(new Date(2024, 0, 20), 14),
  })

  return (
    <div className="mx-auto p-6 space-y-5">
      <div className="flex justify-between items-center flex-col sm:flex-row">
        <h1 className="text-3xl font-bold">Company Performance Dashboard</h1>
        <DatePickerWithRange date={date} setDate={setDate} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
        { dashboardCards.map((card, index) => (
          <Card key={index} className="p-5 rounded-xl">
            <CardHeader className="p-0 flex justify-between flex-row">
              <CardTitle className="text-md text-muted-foreground">{card.title}</CardTitle>
              <card.icon className="text-muted-foreground" size={18} strokeWidth={2}/>
            </CardHeader>
            <CardContent className="p-0">
              <h1 className="text-2xl font-bold">{card.value}</h1>
            </CardContent>
            <CardFooter className="p-0 mt-1">
              <CardDescription className="text-green-600">
                {card.description}
              </CardDescription>
            </CardFooter>
          </Card>
        )) }
      </div>

      <div className="flex flex-col lg:flex-row gap-5">
        <RevenueVsTargetChart className="w-full lg:w-3/5" />
        <EmployeeDistributionChart className="w-full lg:w-2/5" />
      </div>

      <div className="flex flex-col lg:flex-row gap-5">
        <DealsClosedChart className="w-full lg:w-3/5" />
        <SupportResolutionChart className="w-full lg:w-2/5" />
      </div>
        
      <div className="flex flex-col lg:flex-row gap-5">
        <CashFlowChart className="w-full lg:w-3/5" />
        <EmployeeTurnoverChart className="w-full lg:w-2/5" />
      </div>
    </div>
  );
}

export default DashboardPage;
