import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import SignInPage from '../routes/sign-in';
import SignUpPage from '../routes/sign-up';
import ActivationPage from '../routes/auth/activation';
import RecoveryPage from '../routes/auth/recovery';
import ResetPage from '../routes/auth/reset';
import CompanyOnboardingPage from '../routes/company-onboarding';
import DashboardPage from '../routes/dashboard';
import NotFoundPage from '../routes/not-found';
import ConnectionsPage from '../routes/connections';
import RedirectPage from '../routes/connections/redirect';
import SettingsPage from '../routes/settings';
import ProfileSettings from '../routes/settings/profile';
import AppearanceSettings from '../routes/settings/appearance';
import { LoggedInPageLayout } from '../components/logged-in-page-layout';
import { SettingsPageLayout } from '../components/settings-page-layout';

const router = createBrowserRouter([
  {
    path: '/sign-in',
    element: <SignInPage />,
  },
  {
    path: '/sign-up',
    element: <SignUpPage />,
  },
  {
    path: '/auth/activation/:id/:code?',
    element: <ActivationPage />,
  },
  {
    path: '/auth/recovery',
    element: <RecoveryPage />,
  },
  {
    path: '/auth/reset/:id/:code?',
    element: <ResetPage />,
  },
  {
    path: '/company-onboarding',
    element: <CompanyOnboardingPage />,
  },
  {
    path: '/',
    element: (
      <LoggedInPageLayout>
        <DashboardPage />
      </LoggedInPageLayout>
    ),
  },
  {
    path: '/connections',
    element: (
      <LoggedInPageLayout>
        <ConnectionsPage />
      </LoggedInPageLayout>
    ),
  },
  {
    path: '/connections/redirect',
    element: (
      <LoggedInPageLayout>
        <RedirectPage />
      </LoggedInPageLayout>
    ),
  },
  {
    path: '/settings',
    element: (
      <LoggedInPageLayout>
        <SettingsPage />
      </LoggedInPageLayout>
    ),
  },
  {
    path: '/settings/profile',
    element: (
      <LoggedInPageLayout>
        <SettingsPageLayout>
          <ProfileSettings />
        </SettingsPageLayout>
      </LoggedInPageLayout>
    ),
  },
  {
    path: '/settings/appearance',
    element: (
      <LoggedInPageLayout>
        <SettingsPageLayout>
          <AppearanceSettings />
        </SettingsPageLayout>
      </LoggedInPageLayout>
    ),
  },
  {
    path: '*',
    element: <NotFoundPage />,
  }
]);

function AppRouter() {
  return <RouterProvider router={router} />;
}

export { AppRouter };