import { useState } from "react";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "lucide-react";

import { cn } from "@/lib/utils"
import { logoHooks } from "@/hooks/logos-hooks";
import { Sheet, SheetContent, SheetTitle, SheetTrigger } from "../ui/sheet";
import { Button } from "../ui/button";
import { navLinks } from "./navbar";
import NavbarDropdown from "./navbar-dropdown";
import { Separator } from "../ui/separator";

const MobileNav = () => {
  const { pathname } = useLocation();
  const logoIconUrl = logoHooks.useLogoIcon();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="mx-auto w-full flex md:hidden justify-between items-center max-w-screen-xl 2xl:max-w-[1700px] h-14 py-1 pr-1">
      <Link to="/dashboard" className="flex items-center space-x-2">
        <div className="flex justify-center items-center h-14 w-20 bg-primary">
          <img src={logoIconUrl} alt="OpexAI" className="h-14 w-14" /> 
        </div>
      </Link>
      <div className="flex items-center md:hidden space-x-2">
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
          <SheetTrigger>
            <Button variant="ghost">
              <Menu className="!w-6 !h-6" />
            </Button>
          </SheetTrigger>
          <SheetContent side="right" className="w-[300px] sm:w-[400px] flex justify-between flex-col">
            <VisuallyHidden.Root>
              <SheetTitle>Menu</SheetTitle>
            </VisuallyHidden.Root>
            <nav className="flex flex-col gap-2">
              {navLinks.map((link) => (
                <Link
                  key={link.label}
                  to={link.to}
                  className={cn(
                    "block px-2 py-1 text-lg",
                    pathname === link.to ? "text-foreground font-semibold" : "text-foreground/60"
                  )}
                  onClick={() => setIsOpen(false)}
                >
                  {link.label}
                </Link>
              ))}
            </nav>
            <div className="space-y-4">
              <Separator />
              <NavbarDropdown 
                isMobile 
                closeMobileNav={() => setIsOpen(false)}
              />
            </div>
          </SheetContent>
        </Sheet>
      </div>
    </nav>
  )
}

export default MobileNav;