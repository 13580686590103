import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export const APP_BASE_URL = window.location.origin;

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const BOT_AVATAR_URL = `${APP_BASE_URL}/images/bot-avatar.jpg`;

export const OAUTH_RESULT = "OAUTH_RESULT";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
const activationCodeRegex = /^[0-9]{4}$/;

export const formatUtils = {
  emailRegex,
  activationCodeRegex,
  domainRegex,
  capitalizeFirstLetter: (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }   
}