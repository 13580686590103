import { api } from '@/lib/api'
import { ChatAdapter, StreamingAdapterObserver } from '@nlux/react'
import { AxiosResponse} from 'axios'
import { Readable } from 'stream'
import { z } from 'zod'

const ReplyDataSchema = z.object({ reply: z.string() });

export const batchCopilotAdapter = async (prompt: string): Promise<string> => {
    try {
      const reply = ReplyDataSchema.parse(
        await api.post('/copilot', { prompt })
      ).reply
    return reply
    } catch (error) {
      return 'Sorry, Error connecting to server :('
    }
}

export const copilotAdapter: () => ChatAdapter = () => ({
    streamText: async (
        prompt: string,
        observer: StreamingAdapterObserver,
    ) => {
        const response :AxiosResponse<Readable> = 
          await api.post('/copilot', {prompt},undefined,undefined,/* {responseType: 'stream'} */)

        if (response.status !== 200) {
            observer.error(new Error('Failed to connect to the server'));
            return
        }

        if (!response.data) {
            return
        }

        // Read a stream of server-sent events
        // and feed them to the observer as they are being generated
        const reader = response.data
        const textDecoder = new TextDecoder();

        let doneStream = false;
        while (!doneStream) {
            const {value, done} = await reader.read()
            if (done) {
                doneStream = true
            } else {
                const content = textDecoder.decode(value)
                if (content) {
                    observer.next(content)
                }
            }
        }

        observer.complete()
    },
})