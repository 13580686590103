import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Separator } from "@/components/ui/separator"
import { 
  Form, 
  FormControl, 
  FormField, 
  FormItem, 
  FormLabel, 
  FormMessage, 
  FormDescription 
} from "@/components/ui/form"
import { formatUtils } from "@/lib/utils"

const updateProfileSchema = z.object({
  firstName: z.string().min(1, 'First name is required').max(50),
  lastName: z.string().min(1, 'Last name is required').max(50),
  email: z.string().regex(formatUtils.emailRegex, 'Invalid email address'),
})

type UpdateProfileValues = z.infer<typeof updateProfileSchema>;

const ProfileSettings = () => {
  const form = useForm<UpdateProfileValues>({
    resolver: zodResolver(updateProfileSchema),
    defaultValues: {
      firstName: 'Max',
      lastName: 'Robinson',
      email: 'user@example.com',
    }
  })

  const onSubmit = (values: UpdateProfileValues) => {
    form.setError('root.serverError', {
      message: undefined,
    });
    console.log(values);
  }

  return (
    <main className="flex-1 space-y-6 max-w-2xl">
      <div>
        <h2 className="text-2xl font-semibold mb-2">
          Profile
        </h2>
        <p className="text-muted-foreground mb-4">
          This is how others will see you on the site.
        </p>
      </div>
      <Separator />
      <div className="space-y-4">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Fist name</FormLabel>
                  <FormControl>
                    <Input placeholder="Max" {...field} />
                  </FormControl>
                  <FormDescription>
                    This is your first name. It can be your real name or a pseudonym.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last name</FormLabel>
                  <FormControl>
                    <Input placeholder="Robinson" {...field} />
                  </FormControl>
                  <FormDescription>
                    This is your last name.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="m@example.com" {...field} />
                  </FormControl>
                  <FormDescription>
                    This is your email address. You use it to log in to your account.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            {form?.formState?.errors?.root?.serverError && (
              <FormMessage>
                {form.formState.errors.root.serverError.message}
              </FormMessage>
            )}
            <Button type="submit" className="w-fit">Update Profile</Button>
          </form>
        </Form>
      </div>
    </main>
  )
}

export default ProfileSettings;
