import { useState } from "react"
import { TimerReset } from "lucide-react"
import { useMutation } from "@tanstack/react-query"

import { 
  DispayConnectionInterval, 
  ConnectionView, 
  ConnectionSpecView,
  ConnectionViewSchema, 
} from "@/app/routes/connections"
import { 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue 
} from "@/components/ui/select"
import { 
  Dialog, 
  DialogContent, 
  DialogDescription, 
  DialogFooter, 
  DialogHeader, 
  DialogTitle, 
  DialogTrigger 
} from "@/components/ui/dialog"
import { DropdownMenuItem } from "@/components/ui/dropdown-menu"
import { Label } from "@/components/ui/label"
import { Button } from "@/components/ui/button"
import { useToast } from "@/hooks/use-toast"
import { connectionsApi } from "@/lib/connections-api"
import { formatUtils } from "@/lib/utils"

type SelectIntervalDialogProps = {
  connection: ConnectionView,
  updateConnections: React.Dispatch<React.SetStateAction<(ConnectionView | ConnectionSpecView)[]>>
}

const SelectIntervalDialog = ({ connection, updateConnections }: SelectIntervalDialogProps) => {
  const { toast } = useToast();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentInterval, setCurrentInterval] = useState<DispayConnectionInterval>(connection.interval);

  const handleUpdateInterval = async () => {
    if(connection.interval === currentInterval) {
      setIsOpen(false);
      return;
    };
    setIsLoading(true);

    await mutateIntervalFn({
      providerName: connection.name,
      requestData: { interval: currentInterval }
    });
  }

  const { mutate: mutateIntervalFn } = useMutation({ 
    mutationFn: connectionsApi.updateInterval,
    onSuccess: async (data, variables) => {
      const { providerName, requestData } = variables;
      const result = ConnectionViewSchema.safeParse(data);
      if (!result.success) return;
      
      updateConnections(prevConnections => {
        return prevConnections.map(conn => {
          if (conn.name === providerName) {
            return { ...conn, interval: requestData.interval };
          }
          return conn;
        });
      })
      const newInterval = formatUtils
        .capitalizeFirstLetter(
          DispayConnectionInterval[requestData.interval].toLocaleLowerCase()
        );
      toast({
        variant: "success",
        title: "Interval updated",
        description: `${ providerName } interval updated to ${ newInterval }`,
      })
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        title: "Error",
        description: `Failed to update interval: ${error.message}`,
      });
    },
    onSettled: () => {
      setIsOpen(false);
    }
  })

  const handleOpenChange = (open: boolean) => {
    setIsLoading(false);
    setIsOpen(open);
    if(open) {
      setCurrentInterval(connection.interval);
    }
  };
  
  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <DropdownMenuItem className="cursor-pointer" onSelect={(e) => e.preventDefault()}>
          <TimerReset className="mr-2 h-4 w-4" />
          <span>Interval</span>
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Set connection interval</DialogTitle>
          <DialogDescription>
            Set the interval for the {connection.name} connection. Click save when you're done.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Interval
            </Label>
            <Select
              value={DispayConnectionInterval[currentInterval]} 
              onValueChange={(value) => setCurrentInterval(DispayConnectionInterval[value as keyof typeof DispayConnectionInterval])}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select the interval" />
              </SelectTrigger>
              <SelectContent>
                {Object.keys(DispayConnectionInterval).filter(key => isNaN(Number(key))).map(interval => (
                  <SelectItem key={interval} value={interval}>
                    {formatUtils.capitalizeFirstLetter(interval.toLocaleLowerCase())}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        <DialogFooter>
          <Button
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            onClick={handleUpdateInterval}
          >
            Save changes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export { SelectIntervalDialog }
