import { authApi } from "./auth-api";

const isLoggedIn = 'isLoggedIn';
const tokenKey = 'token';
const currentUserKey = 'currentUser';

export const authenticationSession = {
  saveResponse(response: any) {
    localStorage.setItem(tokenKey, response.token);
    localStorage.setItem(currentUserKey, JSON.stringify(response));
  },
  getToken(): string | null {
    return localStorage.getItem(tokenKey) ?? null;
  },
  isLoggedIn(): boolean {
    return localStorage.getItem(isLoggedIn) === JSON.stringify(true);
  },
  async verifyToken(): Promise<boolean> {
    try {
      const response = await authApi.check({});
      if (response.success) {
        localStorage.setItem(isLoggedIn, JSON.stringify(true));
        return true;
      }
      localStorage.setItem(isLoggedIn, JSON.stringify(false));
      return false
    } catch (error) {
      localStorage.setItem(isLoggedIn, JSON.stringify(false));
      return false;
    }
  },
  clearSession() {
    localStorage.removeItem(isLoggedIn);
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(currentUserKey);
  },
  logOut() {
    this.clearSession();

    try {
      authApi.logout({});
    } catch (error) {
      console.error("Something went wrong while logging out:", error);
    }

    window.location.href = '/sign-in';
  },
  getCurrentUser(): unknown | null {
    const user = localStorage.getItem(currentUserKey);
    if (user) {
      try {
        return JSON.parse(user);
      } catch (e) {
        console.error(e);
        return null;
      }
    }
    return null;
  },
}