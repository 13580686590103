import { useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { useMutation } from '@tanstack/react-query'

import { LoadingSpinner } from '@/components/ui/loading-spinner'
import { connectionsApi } from '@/lib/connections-api'
import { OAUTH_RESULT } from '@/lib/utils'

const RedirectPage = () => {
  const [error, setError] = useState<string>();

  useEffect(() => {
    mutate(window.location.search);
  }, [])

  const { mutate } = useMutation({ 
    mutationFn: connectionsApi.add,
    onSuccess: async () => {
      window.opener.postMessage({ type: OAUTH_RESULT, isSuccess: true }, '*');
      window.close();
    },
    onError: (error) => {
      console.error('OAuth callback error:', error);
      window.opener.postMessage({ type: 'OAUTH_RESULT', isSuccess: false }, '*')
      setError(error instanceof AxiosError ? error.response?.data : 'Connection failed');
      setTimeout(() => window.close(), 5000);
    }
  })
  
  return (
    <div className="flex flex-col items-center justify-center min-h-[95%] space-y-4">
      {error ? (
        <>
          <h2 className="text-2xl text-destructive">Connection Failed</h2>
          <p>{error}</p>
          <p>Redirecting to connections page...</p>
        </>
      ) : (
        <>
          <LoadingSpinner size={54} />
          <p className="text-xl">Verifying your data...</p>
        </>
      )}
    </div>
  )
}

export default RedirectPage
