import { useTheme } from "@/components/theme-provider"
import { RadioGroup } from "./ui/radio-group"
import { ThemeOption } from "@/features/settings/appearance/theme-option"
 
export function ThemeSelector() {
  const { theme, setTheme } = useTheme();
 
  return (
    <RadioGroup 
      defaultValue={theme} 
      onValueChange={setTheme} 
      className="grid grid-cols-2 gap-4 w-full md:w-3/4"
    >
      <ThemeOption value="light" label="Light"/>
      <ThemeOption value="dark" label="Dark" />
    </RadioGroup>
  )
}