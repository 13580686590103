import { Fragment } from "react";
import { 
  LogOut, 
  User, 
  ReceiptText, 
  Shield, 
  HandCoins, 
  Settings 
} from "lucide-react";

import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem, 
  DropdownMenuLabel, 
  DropdownMenuSeparator, 
  DropdownMenuTrigger 
} from "../ui/dropdown-menu";
import { authenticationSession } from "@/lib/auth-session";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import LinkOptional from "./link-optional";
import { cn } from "@/lib/utils";

export const dropdownItems = [
  { 
    icon: User,
    label: 'Profile',
  },
  { 
    icon: ReceiptText, 
    label: 'Billing' 
  },
  { 
    icon: Shield, 
    label: 'Team' 
  },
  { 
    icon: HandCoins, 
    label: 'Subscription'
  },
  { 
    icon: Settings, 
    label: 'Settings', 
    link: '/settings',
    separator: true,
  },
  { 
    icon: LogOut, 
    label: 'Log Out', 
    className: 'text-destructive', 
    onClick: () => authenticationSession.logOut(), 
  },
]

type NavbarDropdownProps = {
  isMobile: boolean;
  closeMobileNav?: () => void;
}

const NavbarDropdown = ({
  isMobile,
  closeMobileNav
}: NavbarDropdownProps) => {
  const handleMenuSelect = (item: typeof dropdownItems[number]) => {
    if(item.onClick) {
      item.onClick();
    }
    closeMobileNav?.();
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className={cn(isMobile && "flex space-x-2")}>
        <Avatar className="cursor-pointer">
          <AvatarImage src="https://github.com/shadcn.png" />
          <AvatarFallback>CN</AvatarFallback>
        </Avatar>
        {isMobile && (
          <DropdownMenuLabel className="text-md">Max Robinson</DropdownMenuLabel>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent className={cn("w-56 mt-1", !isMobile && "mr-24")}>
        {!isMobile && (
          <>
            <DropdownMenuLabel className="text-md">Max Robinson</DropdownMenuLabel>
            <DropdownMenuSeparator />
          </>
        )}
        {dropdownItems.map(item => (
          <Fragment key={item.label}>
            <LinkOptional link={item.link}>
              <DropdownMenuItem className="cursor-pointer" onSelect={() => handleMenuSelect(item)}>
                <item.icon className={`mr-2 h-4 w-4 ${item.className}`} />
                <span className={item.className}>{item.label}</span>
              </DropdownMenuItem>
            </LinkOptional>
            {item.separator && <DropdownMenuSeparator />}
          </Fragment>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default NavbarDropdown;