import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import FullLogo from "@/components/custom/full-logo";
import { 
  Card, 
  CardContent, 
  CardDescription, 
  CardHeader, 
  CardTitle 
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { authApi } from "@/lib/auth-api";
import { formatUtils } from "@/lib/utils";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";

const resetFormSchema = z.object({
  recoveryCode: z.string().regex(formatUtils.activationCodeRegex, "Recovery code must contain 4 digits"),
  password: z.string().min(6, 'Password must contain at least 6 characters').max(50),
  confirmPassword: z.string().min(6, 'Password must contain at least 6 characters').max(50),
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords do not match",
  path: ["confirmPassword"],
});

type ResetFormValues = z.infer<typeof resetFormSchema>;

const ResetPage = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { id, code: codeParam = "" } = useParams();
  
  const form = useForm<ResetFormValues>({
    resolver: zodResolver(resetFormSchema),
    defaultValues: {
      recoveryCode: codeParam,
      password: "",
      confirmPassword: "",
    }
  })

  const { mutate, isPending } = useMutation({
    mutationFn: authApi.reset,
    onSuccess: (data) => {
      toast({
        variant: "success",
        title: "Password reset",
        description: "Log in with your new password",
      });
      navigate("/sign-in");
    },
    onError: () => {
      form.setError('root.serverError', {
        message: "Something went wrong. Please try again later...",
      });
    }
  })

  const onSubmit = (data: ResetFormValues) => {
    form.setError('root.serverError', {
      message: undefined,
    });
    mutate({
      id,
      recoveryCode: data.recoveryCode,
      password: data.password,
    })
  }

  return (
    <div className="flex min-h-screen flex-col items-center justify-center">
      <FullLogo />
      <Card className="w-full max-w-sm shadow-lg">
        <CardHeader>
          <CardTitle className="text-2xl">Reset password</CardTitle>
          <CardDescription>
            Enter verification code and new password
          </CardDescription>
        </CardHeader>
        <CardContent className="grid gap-4">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="recoveryCode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Verification code</FormLabel>
                    <FormControl>
                      <Input placeholder="0000" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>New password</FormLabel>
                    <FormControl>
                      <Input placeholder="***********" type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="confirmPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Confirm new password</FormLabel>
                    <FormControl>
                      <Input placeholder="***********" type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {form?.formState?.errors?.root?.serverError && (
                <FormMessage>
                  {form.formState.errors.root.serverError.message}
                </FormMessage>
              )}
              <Button
                type="submit"
                className="mt-4 w-full" 
                disabled={isPending} 
                loading={isPending} 
              >
                Reset
              </Button>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
}

export default ResetPage;
