import { useMutation } from "@tanstack/react-query"
import { Unplug } from "lucide-react"
import { Tool } from "@/app/routes/company-onboarding"
import { Button } from "@/components/ui/button"
import { Card, CardContent } from "@/components/ui/card"
import { Label } from "@/components/ui/label"
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select"
import { connectionsApi } from "@/lib/connections-api"
import { useToast } from "@/hooks/use-toast"
import { countConnectedTools } from "./utils"

type ConnectedToolsProps = {
  connections: Record<string, Tool[]>,
  setConnections: React.Dispatch<React.SetStateAction<Record<string, Tool[]>>>,
}

const ConnectedTools = ({
  connections,
  setConnections
}: ConnectedToolsProps) => {
  const { toast } = useToast();

  const disconnectTool = (toolName: string) => {
    setConnections((prevConnections) => {
      const updatedConnections: Record<string, Tool[]> = {}

      for (const [department, tools] of Object.entries(prevConnections)) {
        updatedConnections[department] = tools.map((tool) =>
          tool.name === toolName ? { ...tool, connected: false } : tool
        )
      }

      return updatedConnections
    })
  }

  const { mutate: mutateDeleteFn } = useMutation({ 
    mutationFn: connectionsApi.delete,
    onSuccess: async (_data, name) => {
      toast({
        variant: "success",
        title: "Deleted",
        description: `${name} connection has been deleted`,
      });

      disconnectTool(name)
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        title: "Error",
        description: `Failed delete connecton: ${error.message}`,
      });
    }
  })

  if(countConnectedTools(connections) === 0) {
    return null;
  }
  
  return (
    <Card>
      <CardContent className="p-4 space-y-4">
      {Object.keys(connections).map(department => connections[department].map(tool => tool.connected ? (
        <div key={tool.id} className="flex p-0 space-x-4 items-center">
          <div className="flex-1 space-y-2">
            <Label>Department</Label>
            <Select value={department} disabled>
              <SelectTrigger>
                <SelectValue className="mt-2" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem key={department} value={department}>
                  {department}
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
          
          <div className="flex-1 space-y-2">
            <Label>Tool</Label>
            <Select value={tool.name} disabled>
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem key={tool.id} value={tool.name}>
                  {tool.name}
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
          <Button
            className="py-1 self-end flex-2" 
            variant="outline" 
            type="button"
            onClick={() => mutateDeleteFn(tool.name)}
          >
            <Unplug className="text-destructive" />
            <span className="text-destructive">
              Remove
            </span>
          </Button>
        </div>
      ) : null))}
      </CardContent>
    </Card>
  )
}

export { ConnectedTools }