import { ThemeSelector } from "@/components/theme-selector"
import { Label } from "@/components/ui/label"
import { Separator } from "@/components/ui/separator"

const AppearanceSettings = () => {
  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-2xl font-semibold mb-2">
          Appearance
        </h2>
        <p className="text-muted-foreground mb-4">
          Customize the appearance of the app. Automatically switch between day and night themes.
        </p>
      </div>
      <Separator />
      <div>
        <Label className="block text-sm font-medium mb-1">
          Theme
        </Label>
        <p className="text-muted-foreground text-sm mt-1">
          Select the theme for the dashboard.
        </p>
      </div>
      <ThemeSelector />
    </div>
  )
}

export default AppearanceSettings;
