import { Bar, BarChart, CartesianGrid, XAxis } from "recharts"
import { TrendingUp } from "lucide-react"
import { supportMetrics } from "@/app/routes/dashboard"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart"
import { cn } from "@/lib/utils"

const supportChartConfig = {
  desktop: {
    label: "Desktop",
    color: "hsl(var(--chart-1))",
  },
  mobile: {
    label: "Mobile",
    color: "hsl(var(--chart-5))",
  },
} satisfies ChartConfig

type SupportResolutionChartProps = {
  className?: string,
}

const SupportResolutionChart = ({ className }: SupportResolutionChartProps) => {  
  return (
    <Card className={cn("flex flex-col", className)}>
      <CardHeader>
        <CardTitle>Support Ticket Resolution</CardTitle>
        <CardDescription>January - June 2024</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={supportChartConfig} className="min-h-[50px] h-52 w-full">
          <BarChart accessibilityLayer data={supportMetrics}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="category"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="dashed" />}
            />
            <Bar dataKey="tickets" fill="var(--color-mobile)" radius={4} />
            <Bar dataKey="resolved" fill="var(--color-desktop)" radius={4} />
          </BarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col items-start gap-2 text-sm">
        <div className="flex gap-2 font-medium leading-none">
          Resolution Rate Up by 3% This Month<TrendingUp className="h-4 w-4" />
        </div>
        <div className="leading-none text-muted-foreground">
          Displaying total support ticket resolutions grouped by department
        </div>
      </CardFooter>
    </Card>
  )
}

export { SupportResolutionChart }