import { api } from "./api";
import { AuthorizeResponse, IntervalRequest } from "@/app/routes/connections";

type UpdateIntervalParams = {
  providerName: string
  requestData: IntervalRequest
}

export const connectionsApi = {
  getRedirectUrl(providerName: string) {
    return api.get<AuthorizeResponse>(
      `/connections/authorize/${providerName}`
    );
  },
  fetch() {
    return api.get<any>(
      `/connections`
    );
  },
  add(queryStr: string) {
    return api.post<any>(
      `/connections/callback${queryStr}`
    );
  },
  trigger(providerName: string) {
    return api.post<any>(
      `/connections/trigger/${providerName}`
    );
  },
  delete(providerName: string) {
    return api.delete<any>(
      `/connections/${providerName}`
    );
  },
  updateInterval({ providerName, requestData }: UpdateIntervalParams) {
    return api.patch<any>(
      `/connections/${providerName}`,
      requestData
    );
  },
}