import { logoHooks } from "@/hooks/logos-hooks";

const FullLogo = () => {
  const fullLogoUrl = logoHooks.useFullLogo(); 
  return (
    <div className="h-[70px] mb-3">
      <img
        className="h-full"
        src={fullLogoUrl}
        alt="logo"
      />
    </div>
  )
}

export default FullLogo;
