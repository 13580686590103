import { useEffect, useState } from "react"
import { z } from "zod"
import { useMutation } from "@tanstack/react-query"
import { api } from "../../../lib/api"
import { 
  Table,
  TableBody, 
  TableCaption, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from "@/components/ui/table"
import { Badge } from "@/components/ui/badge"
import { isActiveConnection } from "@/features/connections/utils"
import { ConnectionDropdownMenu } from "@/features/connections/connection-dropdown-menu"
import { connectionsApi } from "@/lib/connections-api"
import { useToast } from "@/hooks/use-toast"

//haven't found what we have in terms of showing error to users
const showError = (message:string)=>{}

// Enums
enum ConnectionInterval {// it will be triggered by scheduler, to be implemented on backend
  NONE = 0,
  HOURLY = 1,
  DAILY = 2,
  WEEKLY = 3
}

export enum DispayConnectionInterval {
  MANUAL = ConnectionInterval.NONE,
  HOURLY = ConnectionInterval.HOURLY,
  DAILY = ConnectionInterval.DAILY
}

export enum StrategyView {
  OAUTH = 'oauth',
  API_TOKEN = 'api_token',//this is not oauth, just "copy an access token" sort of authorisation
  IN_DEV = ''
}

// Zod Schemas
const ConnectionStatus = z.enum(['active', 'wanting']) //wanting to indicate some troubles
const ConnectionIntervalSchema = z.nativeEnum(DispayConnectionInterval)
const StrategyViewSchema = z.nativeEnum(StrategyView)

export const ConnectionViewSchema = z.object({ // for existing (connected)
  name: z.string(),
  domains: z.array(z.string()),
  status: ConnectionStatus,
  interval: ConnectionIntervalSchema,
  lastUpdate: z.string().nullable(),
  lastWarnings: z.array(z.string())
})

const ConnectionSpecViewSchema = z.object({ // for not existing
  name: z.string(),
  desc: z.string(),
  department: z.string(),
  domains: z.array(z.string()),
  strategy: StrategyViewSchema
})

export const ListResponseSchema = z.object({
  existing: z.array(ConnectionViewSchema),
  available: z.record(z.string(), z.array(ConnectionSpecViewSchema))
})

export const AuthorizeResponseSchema = z.object({
  redirectUri: z.string().url()
})

// Types
type ConnectionStatus = z.infer<typeof ConnectionStatus>
export type ConnectionView = z.infer<typeof ConnectionViewSchema>
export type ConnectionSpecView = z.infer<typeof ConnectionSpecViewSchema>
export type AuthorizeResponse = z.infer<typeof AuthorizeResponseSchema>

type ApiTokenRequest = {
  token: string
}

export type IntervalRequest = {
  interval: DispayConnectionInterval
}

const ConnectionsPage = () => {
  const { toast } = useToast();
  const [connections, setConnections] = useState<(ConnectionView | ConnectionSpecView)[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => fetchConnections(), [])

  const { mutate: fetchConnections } = useMutation({ 
    mutationFn: connectionsApi.fetch,
    onSuccess: async (data) => {
      const result = ListResponseSchema.safeParse(data);
      if (result.success) {
        setConnections([...result.data.existing, ...(Object.values(result.data.available).flat().slice(0, 5))]);//TODO departments?
      }
    },
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to fetch connections',
      })
    },
    onSettled: () => {
      setIsLoading(false);
    }
  })
  
  const handleApiToken = async (name: string, token: string) => {
    try {
      const requestData: ApiTokenRequest = { token }
      await api.post(`/connections/token/${name}`, requestData) // it's simply saved on backend
      fetchConnections()
    } catch (error) {
      console.error('API token connection error:', error)
      showError('Failed to add API token connection')
    }
  }
  
  if (isLoading) {
    return (
      <div className="p-4 space-y-4">
        {(new Array(5)).map(i => (
          <div key={i} className="h-12 bg-gray-200 animate-pulse rounded-lg"/>
        ))}
      </div>
    )
  }

  return (
    <div className="mx-auto p-6 space-y-5">
      <h1 className="text-3xl font-bold">Connections</h1>
      <Table>
        <TableCaption>A list of your connections</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead >Service</TableHead>
            <TableHead className="w-1/2">Description</TableHead>
            <TableHead>Status</TableHead>
            <TableHead className="text-right">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {connections.map(connection => {
            const isConnected = isActiveConnection(connection);
            return (
              <TableRow key={connection.name}>
                <TableCell className="font-medium">{connection.name}</TableCell>
                <TableCell>{isConnected ? `Default description for ${connection.name}` : connection.desc}</TableCell>
                <TableCell>
                  {isConnected ? (
                    <Badge variant="success">Connected</Badge>
                  ) : (
                    <Badge variant="destructive">Disconnected</Badge>
                  )}
                </TableCell>
                <TableCell className="flex justify-end">
                  <ConnectionDropdownMenu 
                    connection={connection} 
                    setConnections={setConnections} 
                    fetchConnections={fetchConnections}
                  />
                </TableCell>
              </TableRow>
          )})}
        </TableBody>
      </Table>
    </div>
  )
}

export default ConnectionsPage
