import { Bar, BarChart, LabelList, XAxis, YAxis } from "recharts";
import { TrendingDown } from "lucide-react";
import { employeeMetrics } from "@/app/routes/dashboard";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import { cn } from "@/lib/utils";

const turnoverchartConfig = {
  desktop: {
    label: "Desktop",
    color: "hsl(var(--chart-4))",
  },
} satisfies ChartConfig;

type EmployeeTurnoverChartProps = {
  className?: string,
}

const EmployeeTurnoverChart = ({ className }: EmployeeTurnoverChartProps) => {
  return (
    <Card className={cn("flex flex-col", className)}>
      <CardHeader>
        <CardTitle>Employee Turnover by Department</CardTitle>
        <CardDescription>January - June 2024</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={turnoverchartConfig} className="min-h-[50px] h-52 w-full">
          <BarChart
            accessibilityLayer
            data={employeeMetrics}
            layout="vertical"
            margin={{
              left: 16,
            }}
          >
            <XAxis type="number" dataKey="turnover" hide />
            <YAxis
              dataKey="department"
              type="category"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value.slice(0, 3)}
              hide
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="dashed" hideLabel />}
            />
        <Bar
          dataKey="turnover"
          layout="vertical"
          fill="var(--color-desktop)"
          radius={4}
        >
          <LabelList
            dataKey="department"
            position="insideLeft"
            offset={8}
            className="fill-[--color-label]"
            fontSize={12}
          />
          <LabelList
            dataKey="turnover"
            position="right"
            offset={8}
            className="fill-foreground"
            fontSize={12}
          />
        </Bar>
          </BarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col items-start gap-2 text-sm">
        <div className="flex gap-2 font-medium leading-none">
          Employee Turnover Decreased by 5% This Month<TrendingDown className="h-4 w-4" />
        </div>
        <div className="leading-none text-muted-foreground">
          Showing the reduction in employee turnover across departments over the past 6 months
        </div>
      </CardFooter>
    </Card>
  )
}

export { EmployeeTurnoverChart }