import { Check, CircleAlert, ShieldX, Info } from "lucide-react"
import { useToast } from "@/hooks/use-toast"
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "components/ui/toast"

const toastIcons = {
  default: "",
  destructive: <ShieldX className="h-6 w-6" />,
  success: <Check className="h-6 w-6" />,
  info: <Info className="h-6 w-6" />,
  warning: <CircleAlert className="h-6 w-6" />,
}

export function Toaster() {
  const { toasts } = useToast()

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, variant, action, ...props }) {
        return (
          <Toast key={id} variant={variant} {...props}>
            <div className="flex gap-4">
              {variant && toastIcons[variant] && (
                <div className="flex items-center">
                  {toastIcons[variant]}
                </div>
              )}
              <div className="grid items-center gap-1">
                {title && <ToastTitle>{title}</ToastTitle>}
                {description && (
                  <ToastDescription>{description}</ToastDescription>
                )}
              </div>
            </div>
            {action}
            <ToastClose />
          </Toast>
        )
      })}
      <ToastViewport />
    </ToastProvider>
  )
}
