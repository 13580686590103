import { useEffect, useState } from "react"
import { 
  ArrowUp,
  EllipsisVertical, 
  Unplug 
} from "lucide-react"
import { UseMutateFunction, useMutation } from "@tanstack/react-query"

import { 
  DropdownMenu, 
  DropdownMenuContent, 
  DropdownMenuItem, 
  DropdownMenuTrigger 
} from "@/components/ui/dropdown-menu"
import {
  AuthorizeResponseSchema, 
  ConnectionSpecView, 
  ConnectionView, 
  ConnectionViewSchema, 
  StrategyView
} from "@/app/routes/connections"
import { SelectIntervalDialog } from "./select-interval-dialog"
import { isActiveConnection } from "./utils"
import { useToast } from "@/hooks/use-toast"
import { connectionsApi } from "@/lib/connections-api"
import { OAUTH_RESULT } from "@/lib/utils"

type ConnectionDropdownMenuProps = {
  connection: ConnectionView | ConnectionSpecView,
  fetchConnections: UseMutateFunction<any, Error, void, unknown>
  setConnections: React.Dispatch<React.SetStateAction<(ConnectionView | ConnectionSpecView)[]>>
}

const ConnectionDropdownMenu = ({
  connection,
  setConnections,
  fetchConnections
}: ConnectionDropdownMenuProps) => {
  const { toast } = useToast();
  
  const isConnected = isActiveConnection(connection);
  const [triggeringConnection, setTriggeringConnection] = useState<string>();
  
  useEffect(() => {
    const handleOAuthResult = (event: MessageEvent) => {
      if (event.data.type !== OAUTH_RESULT) return;

      if(event.data.isSuccess) {
        fetchConnections();
        return toast({
          variant: "success",
          title: "Authorized",
          description: `${connection.name} connection has been authorized`,
        });
      }

      return toast({
        variant: "destructive",
        title: "Error",
        description: `Failed to authorize ${connection.name}`,
      });
    }

    window.addEventListener('message', handleOAuthResult);

    return () => {
      window.removeEventListener('message', handleOAuthResult);
    }
  }, [])

  const handleOauth = (name: string) => {
    // check if connection is in development
    if(!isActiveConnection(connection) && connection.strategy === StrategyView.IN_DEV) {
      toast({
        variant: "destructive",
        title: "Error",
        description: `${connection.name} OAuth is unavailable`,
      });
      return;
    };
    
    toast({
      variant: "info",
      title: "Authorizing...",
      description: `Starting OAuth authorization for ${connection.name}...`,
    });
    mutateOAuthFn(name);
  }

  const { mutate: mutateOAuthFn } = useMutation({ 
    mutationFn: connectionsApi.getRedirectUrl,
    onSuccess: async (data) => {
      const result = AuthorizeResponseSchema.safeParse(data);
      if (result.success) {
        window.open(
          result.data.redirectUri,
          "_blank",
          "width=600,height=800,top=100,left=100"
        )
      }
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        title: "Error",
        description: `Failed to start OAuth authorization: ${error.message}`,
      });
    }
  })

  const { mutate: mutateDeleteFn } = useMutation({ 
    mutationFn: connectionsApi.delete,
    onSuccess: async (_data, name) => {
      toast({
        variant: "success",
        title: "Deleted",
        description: `${name} connection has been deleted`,
      });
      fetchConnections();
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        title: "Error",
        description: `Failed delete connecton: ${error.message}`,
      });
    }
  })

  const handleTrigger = async (name: string) => {
    setTriggeringConnection(name)
    try {
      //it's waiting till data is fetched on backend, do we have sockets in project, maybe they'll be better than logpolling?
      const data = await connectionsApi.trigger(name);
      const result = ConnectionViewSchema.safeParse(data) // updated (with warnings, last update etc) connectin info is ultimately returned
      if (result.success) {
        // setExisting(existing.map(connection =>
        //   connection.name === name ? result.data : connection
        // ))
      } else {
        console.error('Invalid trigger response:', result.error);
        toast({
          variant: "destructive",
          title: "Error",
          description: 'Invalid trigger response format',
        });
      }
    } catch (error) {
      console.error('Trigger connection error:', error)
      toast({
        variant: "destructive",
        title: "Error",
        description: 'Invalid trigger response format',
      });
    } finally {
      setTriggeringConnection(undefined)
    }
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <EllipsisVertical size={18} className="cursor-pointer hover:text-gray-500" />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem 
          className="cursor-pointer"
          onSelect={() => isConnected ? mutateDeleteFn(connection.name) : handleOauth(connection.name)}
        >
          <Unplug className={`mr-2 h-4 w-4 ${isConnected ? 'text-destructive' : 'text-green-600'}`} />
          <span 
            className={isConnected ? "text-destructive" : "text-green-600"}
          >
            {isConnected ? 'Disconnect' : 'Connect'}
          </span>
        </DropdownMenuItem>
        {isConnected && (
          <DropdownMenuItem className="cursor-pointer" onSelect={() => handleTrigger(connection.name)}>
            <ArrowUp className="mr-2 h-4 w-4" />
            <span>Sync Now</span>
          </DropdownMenuItem>
        )}
        {isConnected && (
          <SelectIntervalDialog connection={connection} updateConnections={setConnections} />
        )}                    
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export { ConnectionDropdownMenu }
